@import "@variables"; @import "@mixin";
.DateRangePicker {
  display: flex;
  height: 100%;
  align-items: center;
  position: initial;
  &Input {
    background-color: transparent;
    &__withBorder {
      display: flex;
      border: initial;
    }
  }
  &_picker {
    background-color: initial;
  }
}

.DayPicker__withBorder {
  border-radius: 10px;
  overflow: hidden;
}

.DateInput {
  width: auto;
  background-color: transparent;
  &_input {
    background-color: transparent;
    padding: 0;
    font-size: $font-12px;
    color: $black-2;
    border-bottom: initial;
    font-weight: 700;
    &__focused {
      border-bottom: initial;
    }
    &::placeholder {
      color: $gray-6 !important;
    }
  }
}

.DateRangePickerInput_arrow {
  display: flex;
  align-items: center;
  margin: 0 10px;
  &_svg {
    width: 10px;
    height: 10px;
  }
}

.CalendarDay,
.CalendarDay:hover {
  border: none;
  border-radius: 50%;
}

.DateRangePicker_picker__directionLeft {
  left: 0 !important;
}

.CalendarDay__blocked_out_of_range {
  color: $gray-7 !important;
}

.CalendarDay__default {
  font-size: $font-12px;
  color: $black-2;
  &:hover {
    color: $blue-1;
  }
}

.CalendarDay__selected {
  background-color: rgba($blue-1, 0.2);
  color: $blue-1;
  font-weight: bold;
  &:hover,
  &:active {
    background-color: rgba($blue-1, 0.2);
    color: $blue-1 !important;
  }
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span,
.CalendarDay__hovered_span:hover {
  background-color: rgba($blue-1, 0.1) !important;
  border: initial;
  color: initial;
}

.CalendarDay__selected_span {
  &:before {
    background-color: rgba($blue-1, 0.1) !important;
  }
}

.CalendarMonth_caption {
  font-size: $font-16px;
  line-height: 24px;
}

.DayPicker_weekHeader_li {
  color: $blue-2;
}

.SingleDatePicker {
  display: flex;
  height: 100%;
  align-items: center;
  position: initial;
  padding: 12px 17px;
  > div {
    width: 100%;
    > div {
      width: inherit;
    }
  }
  &Input {
    background-color: transparent;
    &__withBorder {
      display: flex;
      border: initial;
    }
  }
  &_picker {
    &__directionLeft {
      margin-top: 10px;
      border-radius: 10px;
      > .DayPicker__withBorder {
        box-shadow: initial;
      }
    }
  }
}

.DateInput {
  width: 100%;
}

@include screen-mobile {
  .DateRangePicker_picker {
    width: 100%;
  }

  .CalendarMonthGrid__horizontal {
    left: 0;
  }

  .DayPicker_transitionContainer__horizontal,
  .DayPicker__withBorder,
  .DayPicker > div > div,
  .CalendarMonthGrid_month__horizontal,
  .CalendarMonthGrid,
  .CalendarMonth_table,
  .DayPicker_weekHeaders__horizontal {
    width: 100% !important;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: initial;
  }

  .CalendarMonth_table {
    > tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
      > tr {
        display: inherit;
        justify-content: space-around;
        > td {
          display: inherit;
          justify-content: center;
          align-items: center;
          min-width: 39px;
        }
      }
    }
  }

  .DayPicker_weekHeader {
    width: 100%;
    &_ul {
      display: flex;
      justify-content: space-around;
      width: inherit;
    }
  }

  .SingleDatePicker {
    display: flex;
    height: 100%;
    align-items: center;
    position: initial;
    padding: 12px 17px;
    &Input {
      background-color: transparent;
      &__withBorder {
        display: flex;
        border: initial;
      }
    }
    &_picker {
      &__directionLeft {
        margin-top: 10px;
      }
    }
    &_picker__directionLeft {
      width: 100%;
      > .DayPicker__withBorder {
        border: 1px solid $gray-8;
      }
    }
  }
}

.CalendarDay__selected_span, .CalendarDay__selected_span:hover {
  background: rgba(0, 122, 255, 0.2) !important;
}